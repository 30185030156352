import React from "react";
import { slugify } from "../../../utils/helpers";

export const Newsletter = props => {
  const { field, value, handleChange, fieldId } = props;
  const { type, name, placeholder, required, className, options } = field;
  return (
    <div
      className={`newsletter-field${name ? " active" : ""}${
        required ? " required" : ""
      }`}
    >
      <div className="checkboxes">
        <label htmlFor={fieldId} className="checkbox">
          <input
            name={name}
            id={fieldId}
            checked={value[name] ? true : false}
            type="checkbox"
            onChange={handleChange}
            value="yes"
          />
          {placeholder}
        </label>
      </div>
    </div>
  );
};
