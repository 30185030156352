import React from "react";
import "./FullWidthCarousel.scss";
import { Carousel } from "../../Carousel";
import { ImagePass } from "../../Image/ImagePass";

export const FullWidthCarousel = ({ images }) => {
  const swiperSettings = {
    id: "related-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    pagination: false,
    navigation: true,
    breakpoints: {
      1080: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
    },
  };
  
  if (!images) return null;

  return (
    <section className="full-width-carousel">
      <Carousel swiperSettings={swiperSettings}>
        {Object.keys(images).length > 0 &&
          images?.map((image, index) => {
            return (
              <div className="slide" key={index}>
                <ImagePass src={image.image} />
              </div>
            );
          })}
      </Carousel>
    </section>
  );
};
