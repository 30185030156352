import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";

import "./TwoCardBlock.scss";

export const TwoCardBlock = ({ heading, cards, subheading }) => {

  if (!cards) return null;
  
  return (
    <section className="two-card-block">
      <div className="wrap">
        <h2 className="heading">{heading}</h2>
        {subheading && (
          <section
            className="section"
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        )}
        <div className="cards">
          {Object.keys(cards).length > 0 &&
            cards?.map((card, index) => {
              return (
                <div className="card" key={index}>
                  <div className="image-container">
                    <ImagePass src={card.image} />
                  </div>
                  <div className="card-content">
                    <RenderContent content={card.content} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
