import React from "react";
import "./FullWidthImageContained.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";

export const FullWidthImageContained = ({ backgroundImage, content }) => {
  return (
    <section className="full-width-contained">
      <div className="wrap">
        <div className="content">
          <RenderContent content={content} />
        </div>
        <ImagePass src={backgroundImage} className="background-image" />
      </div>
    </section>
  );
};
