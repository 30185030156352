import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Keyboard,
  Mousewheel,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import "./Carousel.scss";

SwiperCore.use([Navigation, Pagination, Keyboard, Mousewheel, Autoplay]);

export const Carousel = ({ children, swiperSettings }) => {
  return (
    <Swiper {...swiperSettings}>
      {Object.keys(children).length > 0 &&
        children?.map((child, slide_index) => {
          return <SwiperSlide key={slide_index}>{child}</SwiperSlide>;
        })}
    </Swiper>
  );
};
