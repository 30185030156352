import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./FullWidthImage.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";

export const FullWidthImage = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      className={`full-width-image ${
        props.content ? "full-width-image--overlay" : ""
      }`}
    >
      <ImagePass src={props.backgroundImage} className="background-image" />
      <div className="wrap">
        <div className="content">
          <div data-aos="fade-left">
            <RenderContent content={props.content} />
          </div>
        </div>
      </div>
    </section>
  );
};
