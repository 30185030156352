import React, { PureComponent, PropTypes } from 'react';
import loadable from '@loadable/component'
import RenderContent from '../RenderContent';

const permittedShortCodes = ['ContactForm', 'Gallery', 'InlineImage']

const shortcodeRegex = /\[[^\]]{2,}]/g;
const shortcodeSplittingRegex = /[[\]\s]/g;
const shortcodeArgSplitter = /&#8221;|&#8243;|&#8221;|=/g;

const generateFilteredShortcodeRegex = (filters) => {
  if (filters.length === 0) {
    return shortcodeRegex;
  }
  const expression = `\\[[^[(${filters.join('|')}|\\])]{2,}]`;
  return new RegExp(expression, 'g');
}

const shortcodeArgsToObject = rawArgs => {
  const shortcodeArgs = rawArgs.reduce((accumulator, rawArg) => {
    const argParts = rawArg.split(shortcodeArgSplitter).filter(argPart => argPart !== '');
    accumulator[argParts[0]] = argParts[1] || '';
    return accumulator;
  }, {});
  return shortcodeArgs;
};

export class RenderShortcode extends PureComponent {
  constructor(props) {
    super(props);
    const { content } = props;
    //var cleanContent = content.replace(new RegExp('/><img', 'g'),'[InlineImage').replace(new RegExp('" /></', 'g'),'"]');
    const contentChunks = this.buildChunks(content);
    this.state = { contentChunks };
  }

  componentWillReceiveProps(nextProps) {
    const { content } = nextProps;
    const { content: oldContent } = this.props;

    if (content !== oldContent) {
      const contentChunks = this.buildChunks(content);
      this.setState({contentChunks});
    }
  }

  extractShortcodeParts(shortcode) {
    const shortcodeParts = shortcode.split(shortcodeSplittingRegex);
    const shortCodeName = shortcodeParts[1];
    const shortCodeRawArgs = shortcodeParts.slice(2);

    const shortCodeComponentName = shortCodeName[0].toUpperCase() + shortCodeName.substring(1);
    const shortcodeArgs = shortcodeArgsToObject(shortCodeRawArgs);
    //console.log('shortCodeComponentName: ',shortCodeComponentName)
    //const ShortCode = Shortcodes[shortCodeComponentName];
    if (permittedShortCodes.includes(shortCodeComponentName)) {
      const ShortCode = loadable(props => import(`./Shortcodes/${shortCodeComponentName}`))
      return {
        ShortCode,
        shortcodeArgs,
        shortCodeName,
      }
    }
    return shortcode


  }

  buildChunks(content) {
    // if it's not splitable, it means we cannot extract shortcodes as it will have a render prop
    if (content === null || typeof content !== 'string') {
      return content;
    }

    const shortcodeMatchesWithFilters = (content.match(shortcodeRegex) || [])
      .map(this.extractShortcodeParts)
      .reduce((accumulator, match) => {
        //console.log('match: ',match)
        const { ShortCode, shortCodeName } = match;
        if (!!ShortCode) {
          accumulator.shortcodeMatches.push(match);
        } else {
          accumulator.excludedCodes.push(shortCodeName);
        }

        return accumulator;
      }, {shortcodeMatches: [], excludedCodes: []});

      const { shortcodeMatches, excludedCodes } = shortcodeMatchesWithFilters;

    // we didn't find any shortcodes, so just push it back
    if (shortcodeMatches.length === 0) {
      //console.log('Normal render!')
      return <RenderContent {...this.props} content={content} />;
    }
    const filteredShortcodeRegex = generateFilteredShortcodeRegex(excludedCodes);
    const contentSplit = content.split(filteredShortcodeRegex);
    const contentParts = contentSplit.reduce((accumulator, value, index) => {
      accumulator.push(<RenderContent {...this.props} content={value} element="span" key={`${index}-content`} />);
      if (shortcodeMatches.length <= index) {
        return accumulator;
      }

      const { ShortCode, shortcodeArgs } = shortcodeMatches[index];



      if (!ShortCode) {
        throw new Error(`The shortcode ${shortCodeComponentName} could not be found`);
      }
      //console.log('shortcodeArgs:',shortcodeArgs)
      accumulator.push(<ShortCode {...shortcodeArgs} key={index} element="div" key={`${index}-shortcode`} />);
      return accumulator;
    }, []);

    return contentParts;
  }

  render() {

    const { contentChunks } = this.state;

    return <>{contentChunks}</>;
  }
}
/*
RenderShortcode.propTypes = {
  content: PropTypes.string,
  useReactRouter: PropTypes.bool,
  className: PropTypes.string,
  element: PropTypes.string,
  content: PropTypes.string,
};
*/

RenderShortcode.defaultProps = {
  content: '',
};
