import React from "react";
import "./PdfDownload.scss";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import { decodeEntities } from "../../../utils/helpers";

export const PdfDownload = ({ rows }) => {

  if (!rows) return null;
  return (
    <section className="pdf-download">
      <div className="wrap">
        <h2 className="section-heading">PDF Downloads</h2>
        <div className="pdf-grid">
          {Object.keys(rows).length > 0 &&
            rows?.map((item, index) => {
              return (
                <div className="pdf-download-item" key={index}>
                  <div className="image-container">
                    <ImagePass src={item.pdfImage} />
                  </div>
                  <p className="pdf-download-title">
                    {decodeEntities(item.pdfName)}
                  </p>
                  <p>{decodeEntities(item.pdfDescription)}</p>
                  <a href={item.pdf.source_url} className="button--pdf">
                    Download PDF
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
