import React from "react";
import { slugify } from "../../../utils/helpers";

export const File = ({ field, fieldId }) => {
  const {
    label,
    placeholder,
    isRequired,
    className,
    id,
    allowedExtensions = [],
  } = field;
  const sluggedLabel = slugify(label);
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;

  return (
    <div
      className={`${className ? ` ${className}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{label}</label>
      <input
        name={gfName}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        accept={allowedExtensions || ""}
        type="file"
      />
    </div>
  );
};
