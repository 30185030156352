/**
 * Utility functions/helpers specific to our Gravity Forms Component
 */

// ========= //

/**
 * Google TEST Keys used for reCaptcha events.
 * Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
 * Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
 * @example - import { googleTestKeys } from "..";
 * const { site, secret } = googleTestKeys;
 * @returns googleTestKeys.site, googleTestKeys.secret
 */
export const googleTestKeys = {
  site: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  secret: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
};

/**
 * Simple wrapper for pushing data layer events
 * @param {string} event - String of the event name.
 * @param {object} location - Location object to pass to formLocation object in dataLayer.
 */
export const pushDataLayerEvent = (event, location) => {
  window.dataLayer = window?.dataLayer || [];
  if (window?.dataLayer) {
    window.dataLayer.push({
      event,
      formLocation: location,
    });
  }
};

/**
 * Generate a simple unique ID
 * @param {string} prefix A string prefix you wish to use with your unique ID
 * @returns {string} String
 */
export const simpleUniqueId = prefix => {
  function gen4() {
    return Math.random()
      .toString(16)
      .slice(-4);
  }
  return (prefix || "").concat(
    [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join("")
  );
};
