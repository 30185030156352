import React from "react";
import { slugify } from "../../../utils/helpers";

export const Select = (props) => {
  const { field, fieldId } = props;
  const { label, placeholder, isRequired, className, choices, id } = field;
  const sluggedLabel = slugify(label);
  const options = JSON.parse(choices);
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${className ? ` ${className}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{label}</label>
      <select
        name={gfName}
        data-name={sluggedLabel}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        defaultValue=""
      >
        {placeholder ? (
          <option disabled value="">
            {placeholder}
          </option>
        ) : (
          <option disabled value="">
            Select
          </option>
        )}
        {options &&
          options.map((option, index) => (
            <option key={`option-${index}`} value={option?.value || ""}>
              {option.text}
            </option>
          ))}
      </select>
    </div>
  );
};
