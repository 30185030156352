import React from "react";

export const Radio = props => {
  const { field, fieldId } = props;
  const { isRequired, className, choices, id } = field;
  const options = JSON.parse(choices);
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${className ? ` ${className}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <div className="radio">
        {options &&
          options.map((option, index) => (
            <label htmlFor={`${fieldId}-${index}`} key={index}>
              <input
                name={gfName}
                id={`${fieldId}-${index}`}
                type="radio"
                value={option.value}
              />
              {option.text}
            </label>
          ))}
      </div>
    </div>
  );
};
