import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { RelatedPass } from "./RelatedPass";

const RelatedProjects = (props) => {
  const postType = props.contentType ? props.contentType : "post";
  const shouldDisplayCTA =
    (postType === "projects" && props.location.pathname !== "/") ||
    (props.location.pathname !== "/projects/" &&
      props.location.pathname !== "/projects" &&
      !props.location.pathname.includes("projects/page"));

  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          allWordpressWpProjects(sort: { fields: date, order: DESC }) {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
          categories: allWordpressWpProjectCategory(
            filter: { count: { gt: 0 } }
          ) {
            edges {
              node {
                name
                wordpress_id
              }
            }
          }
        }
      `}
      render={(data) => (
        <RelatedPass
          {...props}
          data={data}
          shouldDisplayCTA={shouldDisplayCTA}
        />
      )}
    />
  );
};

export default RelatedProjects;
