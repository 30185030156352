import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./StaffProfiles.scss";
import GatsbyImage from "gatsby-image";

const renderSocialIcon = (social, key) => {
  // Add more socials if needed
  switch (social.socialtype) {
    case "linkedIn":
      return (
        <a
          target="_blank"
          key={`staff-social-${key}`}
          href={social.socialLink}
          rel="noreferrer"
          className="social linkedin"
        ></a>
      );
    default:
      return null;
  }
};

export const StaffProfiles = (props) => {
  const { staffProfiles, title, fullWidthImage } = props;

  return (
    <>
      <div className="intro-section">
        <div className="intro">{title && <h2>{title}</h2>}</div>
        {fullWidthImage && (
          <ImagePass
            className="intro-image"
            src={fullWidthImage}
            position="relative"
          />
        )}
      </div>
      <section className="staff-profiles">
        <div className="wrap">
          <div className="inner">
            <div className="profiles">
              {staffProfiles &&
                staffProfiles.map((profile, index) => (
                  <div className="profile" key={index}>
                    <div className="image">
                      <div className="aspect">
                        <ImagePass src={profile.staffImage} />
                      </div>
                    </div>
                    <div className="content">
                      <h3 className="name">{profile.staffName}</h3>
                      <p className="position">
                        <strong>{profile.staffPosition}</strong>
                      </p>
                      <div className="socials">
                        {profile.staffSocials &&
                          profile.staffSocials.map((social, idx) => {
                            return renderSocialIcon(social, idx);
                          })}
                      </div>
                      <RenderContent content={profile.staffBiography} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
