import React from "react";

export const Hidden = props => {
  const { field, fieldId } = props;
  const { label, className = "", id } = field;
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div className={`hidden-input${className ? ` ${className}` : ""}`}>
      <label htmlFor={fieldId}>{label}</label>
      <input name={gfName} id={fieldId} type="hidden" />
    </div>
  );
};
