import React from "react";
import { navigate } from "gatsby";
import { ImagePass } from "../../Image/ImagePass";

import "./icon-grid.scss";

export const IconGrid = (props) => {
  if (!props) return null;

  const { id, sectionTitle, sectionContent, grid } = props;

  if (!grid) return null;

  const { backgroundColor, gridCellType, items } = grid;

  return (
    <section
      className={`icon-grid${
        backgroundColor === "primary" ? " dark" : " light"
      } ${backgroundColor}`}
    >
      <div className="wrap">
        {sectionTitle && sectionTitle.length > 0 && (
          <h2 className="heading">{sectionTitle}</h2>
        )}
        {sectionContent && sectionContent.length > 0 && (
          <div
            className="intro"
            dangerouslySetInnerHTML={{ __html: sectionContent }}
          />
        )}
        {items.length > 0 && (
          <div className={`items type-${gridCellType}`}>
            {Object.keys(items).length > 0 &&
              items?.map((item, idx) => {
                return (
                  <div
                    className={`item${item.isItemOutlined ? " outlined" : ""}${
                      item.isItemLink ? " link" : ""
                    }${item.backgroundImage ? " has-background" : ""}`}
                    key={`${id}-${idx}`}
                    onClick={
                      item.isItemLink ? () => navigate(item.link) : () => null
                    }
                  >
                    {item.backgroundImage ? (
                      <ImagePass
                        src={item.backgroundImage}
                        alt={item.backgroundImage.title}
                        position="relative"
                        className={`item--image`}
                      />
                    ) : (
                      <div className="item--spacer" />
                    )}
                    <div className="item--content">
                      <div className="item--icon-container">
                        <ImagePass
                          src={item.icon}
                          className="item--icon"
                          position="relative"
                          lazy={false}
                        />
                      </div>
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </section>
  );
};
