/* eslint-disable no-console */
import PropTypes from "prop-types";

/**
 * Requires a Gravity Form ID for matching and the allGfForms object.
 * Returns the array for the provided Gravity Form ID.
 * @param {Number} gravityFormId
 * @param {Array} gravityFormsData
 * @returns {Object[]} Gravity form that matches the provided ID, including all fields.
 */
export const gravityFormExtractor = (gravityFormId, gravityFormsData) => {
  // Short-circuit
  if (!gravityFormId) {
    console.warn("Gravity Form ID is not supplied. Passed: ", gravityFormId);
  }

  if (!gravityFormsData) {
    console.warn("No Gravity Forms Data provided.");
    return [];
  }

  // Use our formID to filter out the correct form data from gravityFormsData
  const extractedForm = gravityFormsData
    .filter((node) => node?.formId === gravityFormId)
    .pop();

  if (!extractedForm?.formFields?.length) {
    console.warn(
      `gravityFormExtractor: Form ID: '${gravityFormId}' returned no fields.`
    );
  }
  return extractedForm;
};

gravityFormExtractor.propTypes = {
  gravityFormId: PropTypes.number.isRequired,
  gravityFormsData: PropTypes.array.isRequired,
};
