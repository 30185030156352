import React from "react";

export const Number = props => {
  const { field, fieldId } = props;
  const { label, placeholder, isRequired, className, id } = field;
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${className ? ` ${className}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{label}</label>
      <input
        name={gfName}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        type="number"
      />
    </div>
  );
};
