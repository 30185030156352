import React, { Component, Fragment } from 'react';
import { decodeEntities } from '../../utils/helpers';
import RenderContent from '../RenderContent'
import './Accordion.scss';

const isClient = typeof window !== 'undefined';
const Item = (props) => {
  const { item, openNavKey, toggleSubNav, keyId } = props;
  //console.log('concertina:',props)
  const { title, content } = item;
  const isopen = openNavKey === keyId;
  return (
    <div key={keyId}>
      <div onClick={event => toggleSubNav(event, keyId)} className={`trigger ${isopen ? 'open' : 'closed'}`}>
        {decodeEntities(title)}
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
      </div>
      <div dangerouslySetInnerHTML={{__html: content}} className={`content ${isopen ? 'open' : 'closed'}`} />
    </div>
  );
}
export default class Accordion extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { concertina, active } = this.props;
    const { openNavKey } = this.state;
    return (
      <div className={`accordion ${active ? 'active' : ''}`}>

        {concertina && concertina.map((col, index) => (
          <Item key={index} keyId={index} item={col} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
        ))}

      </div>
    );
  }
}
