import React, { useState } from "react";
import "./Accordion.scss";
import RenderContent from "../../RenderContent";

const AccordionItem = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return data ? (
    <div className={`accordion-item ${isOpen ? "accordion-item--open" : ""}`}>
      <div
        className="accordion-header"
        onClick={() => setIsOpen((previousState) => !previousState)}
      >
        <p className="accordion-title">{data.heading}</p>
        <button
          className={`accordion-button ${
            isOpen ? "accordion-button--open" : ""
          }`}
        />
      </div>
      {data.content ? (
        <div>
          <RenderContent content={data.content} className="accordion-content" />
        </div>
      ) : null}
    </div>
  ) : null;
};

export const Accordion = ({ intro, rows }) => {
  return (
    <section className="accordion">
      <div className="wrap">
        {intro && (
          <div className="intro">
            <RenderContent content={intro} />
          </div>
        )}
        {rows?.map((item, index) => {
          return <AccordionItem data={item} key={`accordionItem_${index}`} />;
        })}
      </div>
    </section>
  );
};
