import React, { useState, useEffect } from "react";

export const Text = (props) => {
  const { field, fieldId } = props;
  const {
    label,
    placeholder,
    isRequired,
    cssClass = "",
    id,
    defaultValue,
  } = field;

  //
  // SPECIALIST ONLY FOR AQUIP SITE
  // Returns the title of the page if default value is "{embed_post:post_title}"
  //

  const [pageTitle, setPageTitle] = useState();

  useEffect(() => {
    setPageTitle(document.title);
  }, []);

  const getDefaultValue = (defaultValue) => {
    if (defaultValue === "{embed_post:post_title}") {
      return pageTitle;
    } else if (defaultValue === "{service_title}") {
      return props.bookingTitle;
    }
  };

  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${cssClass ? ` ${cssClass}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{label}</label>
      <input
        name={gfName}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        type="text"
        value={getDefaultValue(defaultValue)}
      />
    </div>
  );
};
